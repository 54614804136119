<template>
  <b-card-code>
   <div v-if="$can('create', 'Student')" class="custom-search d-flex mb-2">
      <router-link :to="{ name: 'add-student' }">
        <b-button variant="outline-primary">
          {{ $t("Add New") }}
        </b-button>
      </router-link>
    </div>
      <b-row>
        <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{  $t("Select Flat")  }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('Class')" rules="required">
                <select v-model.number="version_id" class="form-control" name="version_id" id="version_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select Flat</option>
                  <option v-for="(ver, key) in versions" :key="key" :value="ver.id">
                    {{  ver.name  }}
                  </option>
                </select>
                <small class="text-danger">{{  errors[0]  }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        <b-col md="4">
          <b-card-text>
            <span>Select Class </span>
          </b-card-text>
          <validation-provider #default="{ errors }" name="class" rules="">
            <select name="classes_id" v-model.number="classes_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control">
              <option :value="null">Select One</option>
              <option v-for="(cls, key) in classes" :value="cls.id" :key="key">
                {{ cls.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <b-card-text>
            <span>Select Institution</span>
          </b-card-text>
          <validation-provider #default="{ errors }" name="wing" rules="">
            <select name="wing_id" v-model.number="wing_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control">
              <option :value="null">Select One</option>
              <option v-for="(wing, key) in wings" :value="wing.id" :key="key">
                {{ wing.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
       
        <b-col md="4">
          <b-card-text>
            <span>Select Group</span>
          </b-card-text>
          <validation-provider #default="{ errors }" name="student group" rules="">
            <select name="student_group_id" v-model.number="student_group_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
              <option :value="null">Select One</option>
              <option v-for="(grp, key) in student_groups" :value="grp.id" :key="key">
                {{ grp.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <b-card-text>
            <span>Select Status</span>
          </b-card-text>
          <validation-provider #default="{ errors }" name="status" rules="">
            <select name="status_id" v-model.number="status_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control">
              <option :value="null">Select One</option>
              <option :value="1">{{ "Active" }}</option>
              <option :value="0">{{ "InActive" }}</option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input v-model="searchTerm" :placeholder="$t('Search')" type="text" class="d-inline-block" />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx :data="getData" :columns="columns10" :filename="'filename'" :sheetname="'subjects'">
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData" :csv-title="'My_CSV'">
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>

    <!-- table -->
    <vue-good-table style="white-space: nowrap; 
    text-align: center; 
    vertical-align: middle" 
    :columns="columns"
    :rows="students" 
    :rtl="direction" 
    :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }" 
    :pagination-options="{
      enabled: true,
      perPage: pageLength,
    }">
      <template slot="table-row" slot-scope="props">
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex + 1 }}
        </span>
        <span v-else-if="props.column.field === 'image'">
          <template>
            <img :src="
              props.row.image
                ? imgShow(props.row.image)
                : getDefaultImage(props.row.gender)
            " width="50" height="50" alt="" />
          </template>
        </span>
        <span v-else-if="props.column.field === 'classes_id'">
          {{ $store.getters.classByID(props.row.classes_id) }}
        </span>
        <span v-else-if="props.column.field === 'version_id'">
          {{ $store.getters.versionByID(props.row.version_id) }}
        </span>
        <span v-else-if="props.column.field === 'wing_id'">
          {{ $store.getters.wingByID(props.row.wing_id) }}
        </span>
        <span v-else-if="props.column.field === 'student_group_id'">
          {{ $store.getters.student_groupByID(props.row.student_group_id) }}
        </span>

        <span v-else-if="props.column.field === 'academic_year_id'">
          {{ $store.getters.academic_yearByID(props.row.academic_year_id) }}
        </span>
        <span v-else-if="props.column.field === 'is_active'">
          <b-form-checkbox v-if="$can('update', 'Student')" :name="'check-button' + props.row.originalIndex"
            :id="'check-button' + props.row.originalIndex" @change="statusChange(props.row.id)"
            v-model="props.row.is_active" :value="1" switch>
          </b-form-checkbox>
          <b-badge v-else :variant="statusVariant(props.row.is_active)">
            {{ props.row.is_active ? "Active" : "Deactivated" }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template>
              <b-button variant="outline-secondary" @click="viewDetails(props.row)">
                <feather-icon class="text-body" icon="EyeIcon" size="15" />
              </b-button>
              <b-button v-if="$can('update', 'Student')" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click="showModal(props.row)" v-b-modal.modal-login variant="outline-primary">
                <feather-icon class="text-body" icon="EditIcon" size="15" />
              </b-button>
            </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select v-model="pageLength" :options="['10', '15', '20', '50', '100', '500']" class="mx-1" @input="
              (value) => props.perPageChanged({ currentPerPage: value })
            " />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- modal edit-->
    <b-modal ref="my-modal" hide-footer size="lg" :title="$t('Student Information Edit')">
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form method="post" ref="update_student" @submit="validationForm" action="javascript:void(0)">
          <b-row>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Photo") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('student photo')" rules="">
                <b-form-file name="image" v-model="selected_row.student_photo" accept="image/png|image/jpeg|image/jpg"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Select an image')"
                  class="form-control">
                </b-form-file>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Name") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('student name')" rules="required">
                <b-form-input name="name" v-model="selected_row.userable.name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write student name')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Nick Name") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('nick name')" rules="">
                <b-form-input name="nick_name" v-model="selected_row.nick_name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write nick name')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Student Number") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('cadet number')" rules="required">
                <b-form-input name="cadet_no" v-model="selected_row.cadet_no"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write student number')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Student Flat") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('version')" rules="required">
                <select class="form-control" id="version_id" v-model="selected_row.version_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" name="version_id">
                  <option :value="null">Select One</option>
                  <option v-for="(ver, key) in versions" :value="ver.id" :key="key">
                    {{ ver.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Class") }}</span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('class')" rules="required">
                <select name="classes_id" v-model="selected_row.classes_id" disabled
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">{{ $t("Select One") }}</option>
                  <option v-for="(cls, key) in classes" :value="cls.id" :key="key">
                    {{ cls.name }}
                  </option>
                </select>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Email") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('student email')" rules="email">
                <b-form-input name="email" v-model="selected_row.userable.email"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write student email')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Phone Number") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('student phone number')" rules="">
                <b-form-input name="phone_number" v-model="selected_row.userable.phone_number"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write student phone number')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Institution") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('wing')" rules="required">
                <select name="wing_id" @change="filterSections" v-model="selected_row.wing_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">{{ $t("Select One") }}</option>
                  <option v-for="(subject, key) in wings" :value="subject.id" :key="key">
                    {{ subject.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
           
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Group") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('group')" rules="required">
                <select name="student_group_id" v-model.number="selected_row.student_group_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">{{ $t("Select One") }}</option>
                  <option v-for="(group, key) in student_groups" :value="group.id" :key="key">
                    {{ group.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Date of Birth") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('student date of birth')" rules="required">
                <b-form-datepicker name="date_of_birth" v-model="selected_row.date_of_birth"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Select student date of birth')"
                  class="form-control">
                </b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Gender") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('gender')" rules="required">
                <select class="form-control" id="gender_id" v-model.number="selected_row.userable.gender_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" name="gender_id">
                  <option :value="null">Select One</option>
                  <option v-for="(gender, key) in genders" :value="gender.id" :key="key">
                    {{ gender.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Religion") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('religion')" rules="required">
                <select class="form-control" id="religion_id" v-model.number="selected_row.userable.religion_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" name="religion_id">
                  <option :value="null">Select One</option>
                  <option v-for="(religion, key) in religions" :value="religion.id" :key="key">
                    {{ religion.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t("Blood Group") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('blood group')">
                <select class="form-control" id="blood_group" v-model="selected_row.userable.blood_group_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" name="blood_group_id">
                  <option :value="null">Select One</option>
                  <option v-for="(bgrp, key) in blood_groups" :value="bgrp.id" :key="key">
                    {{ bgrp.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            
          </b-row>
          <!-- submit button -->
          <b-col>
            <b-button variant="primary" type="submit">Submit</b-button>
          </b-col>
        </form>
      </validation-observer>
    </b-modal>
    <!-- view model -->
    <b-modal ref="information-view" hide-footer size="xl" :title="$t('Details View')">
      <b-row>
        <b-col lg="4">
          <b-card-group deck>
            <b-card>
              <div class="text-center">
                <b-avatar :src="
                  student.image
                    ? imgShow(student.image)
                    : getDefaultImage(student.wing_id)
                " size="6rem"></b-avatar>
                <h1 class="mb-0">
                  {{ student.userable ? student.userable.name : "" }}
                </h1>
                <h6 class="my-2">Student</h6>
              </div>
              <table>
                <tr>
                  <td class="font-weight-bold">Student No</td>
                  <td>: {{ student.cadet_no }}</td>
                </tr>
                <tr>
                <td>Flat</td>
                <td>: {{ versionByID(student.version_id) }}</td>
              </tr>
                <tr>
                  <td class="font-weight-bold">Role</td>
                  <td>
                    :
                    {{
                        student.userable ? roleByID(student.userable.role_id) : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Institute</td>
                  <td>: {{ wingByID(student.wing_id) }}</td>
                </tr>
              </table>
            </b-card>
          </b-card-group>
        </b-col>
        <b-col lg="8">
          <b-card>
            <b-tabs pills card>
              <b-tab title="Profile" active>
                <b-card-body>
                  <b-row>
                    <b-col md="6">
                      <table>
                        <tr v-if="student.roll">
                          <td>Roll</td>
                          <td>: {{ student.roll }}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>
                            :
                            {{ student.userable ? student.userable.email : "" }}
                          </td>
                        </tr>
                        <tr>
                          <td>Phone number</td>
                          <td>
                            :
                            {{
                                student.userable
                                  ? student.userable.phone_number != null
                                    ? student.userable.phone_number
                                    : "NG"
                                  : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Gender</td>
                          <td>
                            :
                            {{
                                student.userable
                                  ? genderById(student.userable.gender_id)
                                  : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Blood group</td>
                          <td>
                            :
                            {{
                                bloodGroupById(
                                  student.userable
                                    ? student.userable.blood_group_id
                                    : ""
                                )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Religion</td>
                          <td>
                            :
                            {{
                                religionById(
                                  student.userable
                                    ? student.userable.religion_id
                                    : ""
                                )
                            }}
                          </td>
                        </tr>
                        
                        <!-- <tr>
                          <td>Shift</td>
                          <td>: {{ shiftByID(student.shift_id) }}</td>
                        </tr> -->
                        <tr>
                          <td>Class</td>
                          <td>: {{ classByID(student.classes_id) }}</td>
                        </tr>
                      </table>
                    </b-col>
                    <b-col md="6">
                      <table>
                        <tr>
                          <td>Student Group</td>
                          <td>
                            : {{ student_groupByID(student.student_group_id) }}
                          </td>
                        </tr>
                        <tr>
                          <td>Section</td>
                          <td>: {{ sectionById(student.section_id) }}</td>
                        </tr>
                        <tr>
                          <td>Academic year</td>
                          <td>
                            : {{ academic_yearByID(student.academic_year_id) }}
                          </td>
                        </tr>
                        <!-- <tr>
                          <td>Special promotion</td>
                          <td :style="
                            student.is_special_promotion
                              ? 'color:green'
                              : 'color:red'
                          ">
                            :
                            {{
                                student.is_special_promotion === 1 ? "yes" : "no"
                            }}
                          </td>
                        </tr> -->
                        <tr>
                          <td>Date of birth</td>
                          <td>: {{ student.date_of_birth }}</td>
                        </tr>
                        <tr>
                          <td>Nationality</td>
                          <td>: {{ student.nationality }}</td>
                        </tr>
                        <tr>
                          <td>Previous School</td>
                          <td>: {{ student.previous_school }}</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td :style="
                            student.is_active ? 'color:green' : 'color:red'
                          ">
                            :
                            {{
                                student.is_active === 1 ? "active" : "inactive"
                            }}
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-tab>
              <b-tab title="Parent">
                <b-card-body>
                  <b-row>
                    <b-col md="6">
                      <table>
                        <tr>
                          <td>Father's Name</td>
                          <td>
                            :
                            {{
                                student.parent ? student.parent.father_name : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Father's Profession</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.father_profession != null
                                    ? student.parent.father_profession
                                    : "NG"
                                  : "NG"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Father's Monthly Income</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.father_monthly_income != 0
                                    ? student.parent.father_monthly_income
                                    : "NG"
                                  : "NG"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 120px">Father's Phone Number</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.father_phone_number != null
                                    ? student.parent.father_phone_number
                                    : "NG"
                                  : "NG"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Father NID</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.father_nid_no != null
                                    ? student.parent.father_nid_no
                                    : "NG"
                                  : "NG"
                            }}
                          </td>
                        </tr>
                        <tr v-if="
                          student.parent ? student.parent.father_email : ''
                        ">
                          <td>Father Email</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.father_email != null
                                    ? student.parent.father_email
                                    : "NG"
                                  : "NG"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Mother Name</td>
                          <td>
                            :
                            {{
                                student.parent ? student.parent.mother_name : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Mother Profession</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.mother_profession != null
                                    ? student.parent.mother_profession
                                    : "NG"
                                  : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Mother Monthly Income</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.mother_monthly_income != 0
                                    ? student.parent.mother_monthly_income
                                    : "NG"
                                  : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 120px">Mother Phone Number</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.mother_phone_number != null
                                    ? student.parent.mother_phone_number
                                    : "NG"
                                  : "NG"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Mother NID</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.mother_nid_no != null
                                    ? student.parent.mother_nid_no
                                    : "NG"
                                  : "NG"
                            }}
                          </td>
                        </tr>
                      </table>
                    </b-col>
                    <b-col md="6">
                      <table>
                        <tr v-if="
                          student.parent ? student.parent.mother_email : ''
                        ">
                          <td>Mother Email</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.mother_email != null
                                    ? student.parent.mother_email
                                    : "NG"
                                  : "NG"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Present Address</td>
                          <td style="min-width: 120px">
                            :
                            {{
                                student.parent
                                  ? student.parent.present_address != null
                                    ? student.parent.present_address
                                    : "NG"
                                  : "NG"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Permanent Address</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.permanent_address != null
                                    ? student.parent.permanent_address
                                    : "NG"
                                  : "NG"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Local Guardian Name</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.local_guardian_name != null
                                    ? student.parent.local_guardian_name
                                    : "NG"
                                  : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Local Guardian Phone</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.local_guardian_phone_number !=
                                    null
                                    ? student.parent.local_guardian_phone_number
                                    : "NG"
                                  : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Local Guardian Occupation</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.l_g_occupation != null
                                    ? student.parent.l_g_occupation
                                    : "NG"
                                  : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Relation</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.relation != null
                                    ? student.parent.relation
                                    : "NG"
                                  : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Local Permanent Guardian Address</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.l_g_permanent_address != null
                                    ? student.parent.l_g_permanent_address
                                    : "NG"
                                  : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Local Present Guardian Address</td>
                          <td>
                            :
                            {{
                                student.parent
                                  ? student.parent.local_guardian_address != null
                                    ? student.parent.local_guardian_address
                                    : "NG"
                                  : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td :style="
                            student.parent
                              ? student.parent.is_active
                                ? 'color:green'
                                : 'color:red'
                              : 'color:red'
                          ">
                            :
                            {{
                                student.parent
                                  ? student.parent.is_active
                                    ? "active"
                                    : "Inactive"
                                  : "Inactive"
                            }}
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-tab>
              <b-tab title="Disciplinary Records">
                <student-disciplinary-record :student="student" />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
      <!--            {{ students }}-->
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BButton,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCardText,
  BFormFile,
  BRow,
  BCol,
  BFormDatepicker,
  BAvatar,
  BCardGroup,
  BCard,
  BCardBody,
  BTabs,
  BTab,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, email } from "@validations";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import apiCall from "@/libs/axios";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from "vue-json-to-csv";
import constants from "@core/utils/constants";
import Vue from "vue";
import download from "downloadjs";

Vue.use(VueExcelXlsx);
import StudentDisciplinaryRecord from "@/views/student/StudentDisciplinaryRecord";

export default {
  name: "Student",
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BCardText,
    BFormFile,
    BRow,
    BCol,
    BFormDatepicker,
    BBadge,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BFormCheckbox,
    BCardGroup,
    BCard,
    BCardBody,
    BTabs,
    BTab,
    VueJsonToCsv,
    StudentDisciplinaryRecord,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      integer,
      email,
      pageLength: 10,
      dir: false,
      model_mode: "add",
      student_photo: null,
      current_subjects: [], // currently taken subject.
      all_sections: [],
      filter_sections: [],
      name: "",
      selected_row: {},
      student: {},
      columns: [
        {
          label: this.$t("SL"),
          field: "id",
        },
        {
          label: this.$t("Photo"),
          field: "image",
        },
        {
          label: this.$t("Student ID"),
          field: "cadet_no",
        },
        {
          label: this.$t("Seat Number"),
          field: "nick_name",
        },
        {
          label: this.$t("Name"),
          field: "userable.name",
        },
        {
          label: this.$t("Flat"),
          field: "version_id",
        },
        // {
        //   label: this.$t("Class"),
        //   field: "classes_id",
        // },
        // {
        //   label: this.$t("Student Group"),
        //   field: "student_group_id",
        // },
        // {
        //   label: this.$t("Academic Year"),
        //   field: "academic_year_id",
        // },
        {
          label: this.$t("Status"),
          field: "is_active",
        },
        {
          label: this.$t("Action"),
          field: "action",
        },
      ],
      searchTerm: "",
      status: {
        1: this.$t("active"),
        0: this.$t("deactivate"),
      },
      columns10: [
        {
          label: "Student Number",
          field: "StudentNumber",
        },
        {
          label: "Name",
          field: "Name",
        },
        {
          label: "Class",
          field: "Class",
        },
        {
          label: "Institute",
          field: "Wing",
        },
        {
          label: "Student Group",
          field: "StudentGroup",
        },
        {
          label: "Section",
          field: "Section",
        },
        {
          label: "Academic Year",
          field: "AcademicYear",
        },
        // {
        //   label: "Email",
        //   field: "Email",
        // },
        // {
        //   label: "Gender",
        //   field: "Gender",
        // },
        {
          label: "Religion",
          field: "Religion",
        },
        {
          label: "Phone Number",
          field: "PhoneNumber",
        },
        // {
        //   label: "Blood Group",
        //   field: "BloodGroup",
        // },
        // {
        //   label: "Birth Date",
        //   field: "BirthDate",
        // },
        {
          label: "Father Name",
          field: "FatherName",
        },
        {
          label: "Mother Name",
          field: "MotherName",
        },
        {
          label: "Status",
          field: "Status",
        },
        {
          label: "SpecialPromotion",
          field: "IsSpecialPromotion",
        },
        {
          label: "House",
          field: "House",
        },
        {
          label: "Batch",
          field: "Batch",
        },
        {
          label: "Father's Mobile",
          field: "FatherMobile",
        },
        {
          label: "Mother's Mobile",
          field: "MotherMobile",
        },
        {
          label: "Father's Profession",
          field: "FatherProfession",
        },
        {
          label: "Mother's Profession",
          field: "MotherProfession",
        },
      ],
      classes_id: null,
      wing_id: null,
      version_id: null,
      mandatory_subjects: null,
      option_subjects: null,
      student_group_id: null,
      status_id: null,
      allSelected: false,
      selectedSubjects: []
    };
  },
  methods: {
    selectAll: function () {
      this.selectedSubjects = [];
      this.allSelected = !this.allSelected;
     
      if (this.allSelected) {
        for (var subject in this.subjects) {
          this.selectedSubjects.push(this.subjects[subject].id.toString());
        }
        this.compulsory_subjects = this.selectedSubjects;
      }
    },

     updateCheckall(subject){
      if(this.mandatory_subjects.length == this.selectedSubjects.length){
         this.allSelected = true;
      }else{
         this.allSelected = false;
         this.selectedSubjects.pop(this.subjects[subject].id.toString());
         this.compulsory_subjects = this.selectedSubjects;
      }
    },

    viewDetails(row) {
      this.student = row;
      this.$refs["information-view"].show();
    },
    imgShow(path) {
      return process.env.VUE_APP_ENV_RESOURCHURL + path;
    },

    getDefaultImage(id) {
      var imageName = "";
      if (parseInt(id) == 1) {
        imageName = "/student_face.gif";
      } else {
        imageName = "/female_face.png";
      }

      return imageName;
    },

    statusChange(id) {
      apiCall
        .put(`/student/status/change${id}`)
        .then((response) => {
          this.$toaster.success(response.data.message);
          this.$store.commit("STUDENT_STATUS_CHANGE", id);
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message);
          this.$store.dispatch("GET_ALL_STUDENT");
        });
    },
    showModal(row) {
      this.selected_row = row;
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    filterSections() {
      //this.selected_row.section_id=null;
      if (!this.selected_row.wing_id || !this.selected_row.academic_year_id) {
        this.filter_sections = [];
      } else {
        this.filter_sections = this.sections.find(
          (item) => item.id === parseInt(this.selected_row.wing_id)
        )?.sections;
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let data = new FormData(this.$refs.update_student);
          apiCall
            .post(`/student/information/update${this.selected_row.id}`, data)
            .then((response) => {
              if (response.data.status === "success") {
                this.$toaster.success(response.data.message);
                this.hideModal();
                this.$store.dispatch("GET_ALL_STUDENT");
                this.$refs.simpleRules.reset();
              } else {
                this.$toaster.error(response.data.message);
              }
            })
            .catch((error) => {
              if (error.response.status == 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                });
              } else this.$toaster.error(error.response.data.message);
            });
        }
      });
    },
    findSections() {
      let data = new FormData();
      data.append("classes_id", this.selected_row.classes_id);
      data.append("academic_year_id", this.selected_row.academic_year_id);
      this.$store.dispatch("GET_ALL_MAP_SECTION", data);
    },
    pdfGenerate() {
      /*only change able*/
      let title = "Student List";
      let headers = [
        "StudentNumber",
        "Name",
        "Class",
        "Wing",
        "Section",
        "AcademicYear",
        // "Email",
        // "Gender",
        "Religion",
        "PhoneNumber",
        // "BloodGroup",
        // "BirthDate",
        "FatherName",
        "MotherName",
      ];
      /*change able end*/
      let data = new FormData();
      data.append("title", title);
      data.append("columns", JSON.stringify(headers));
      data.append("data", JSON.stringify(this.getData));
      apiCall
        .post("/get/commom/landscape/table/export/pdf", data, {
          responseType: "blob",
        })
        .then((response) => {
          const content = response.headers["content-type"];
          download(response.data, "student_list.pdf", content);
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message);
        });
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: "light-success",
        0: "light-danger",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },

     

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    ...mapGetters([
      "classes",
      "classByID",
      "versions",
      "versionByID",
      "shifts",
      "shiftByID",
      "student_groups",
      "student_groupByID",
      "roles",
      "roleByID",
      'subjects',
      "academic_years",
      "academic_yearByID",
      "active_religions",
      "religions",
      "religionById",
      "genders",
      "genderById",
      "blood_groups",
      "bloodGroupById",
      "wings",
      "wingByID",
      "sections",
      "sectionById",
      'versions'
    ]),
    students() {
      let studentList = this.$store.getters.students;
      if (this.classes_id) {
        studentList = studentList.filter((item) => item.classes_id === this.classes_id);
      }

      if (this.wing_id) {
        studentList = studentList.filter((item) => item.wing_id === this.wing_id);
      }

      if (this.version_id) {
        studentList = studentList.filter((item) => item.version_id === this.version_id);
      }

      if (this.student_group_id) {
        studentList = studentList.filter((item) => item.student_group_id === this.student_group_id);
      }

      if (this.status_id != null) {
        studentList = studentList.filter((item) => item.is_active === this.status_id);
      }

      return studentList;
    },
    getData() {
      return this.students.map((item) => ({
        StudentNumber: item.student_id ? item.cadet_no : "",
        Name: item.userable ? item.userable.name : "",
        Class: item.classes_id ? this.classByID(item.classes_id) : "",
        Version: item.version_id ? this.versionByID(item.version_id) : "",
        Shift: item.shift_id ? this.shiftByID(item.shift_id) : "",
        Wing: item.wing_id ? this.wingByID(item.wing_id) : "",
        StudentGroup: item.student_group_id
          ? this.student_groupByID(item.student_group_id)
          : "",
        Section: item.section_id ? this.sectionById(item.section_id) : "",
        AcademicYear: item.academic_year_id
          ? this.academic_yearByID(item.academic_year_id)
          : "",
        Email: item.userable ? item.userable.email : "",
        Gender: item.userable ? this.genderById(item.userable.gender_id) : "",
        Religion: item.userable
          ? this.religionById(item.userable.religion_id)
          : "",
        PhoneNumber: item.userable ? item.userable.phone_number : "",
        BloodGroup: item.userable
          ? this.bloodGroupById(item.userable.blood_group_id)
          : "",
        BirthDate: item.date_of_birth ? item.date_of_birth : "",
        FatherName: item.parent ? item.parent.father_name : "",
        MotherName: item.parent ? item.parent.mother_name : "",
        IsSpecialPromotion: item.is_special_promotion ? 'Yes' : 'No',
        Batch: item.batch ? item.batch: "",
        FatherMobile: item.parent?item.parent.father_phone_number : "",
        MotherMobile: item.parent?item.parent.mother_phone_number : "",
        FatherProfession: item.parent?item.parent.father_profession : "",
        MotherProfession: item.parent?item.parent.mother_profession : "",
        House:  item.parent?item.house : "",
      }));
    },
  },
  created() {
    if (this.students.length < 1) this.$store.dispatch("GET_ALL_STUDENT");
    if (this.classes.length < 1) this.$store.dispatch("GET_ALL_CLASSES");
    if (this.student_groups.length < 1)
      this.$store.dispatch("GET_ALL_STUDENT_GROUP");
    if (this.academic_years.length < 1)
      this.$store.dispatch("GET_ALL_ACADEMIC_YEAR");
    if (this.wings.length < 1) this.$store.dispatch("GET_ALL_WING");
    if (this.religions.length < 1) this.$store.dispatch("GET_ALL_RELIGION");
    if (this.blood_groups.length < 1)
      this.$store.dispatch("GET_ALL_BLOOD_GROUP");
    if (this.genders.length < 1) this.$store.dispatch("GET_ALL_GENDER");
    if (this.versions.length < 1) this.$store.dispatch("GET_ALL_VERSION");
    if (this.shifts.length < 1) this.$store.dispatch("GET_ALL_SHIFT");
    if (this.roles.length < 1) this.$store.dispatch("GET_ALL_ROLE");
    if (this.genders.length < 1) this.$store.dispatch("GET_ALL_GENDER");
    if (this.sections.length < 1) this.$store.dispatch("GET_ALL_SECTION");
    this.$store.dispatch("GET_ALL_STUDENT_DISCIPLINARY_RECORD");

    if (this.$store.getters.subjects < 1)
      this.$store.dispatch("GET_ALL_SUBJECT");

    if (this.$store.getters.versions < 1) {
      this.$store.dispatch("GET_ALL_VERSION");
    }
  },
  watch: {
    selected_row() {
      if (this.selected_row) {
        this.mandatory_subjects = this.subjects.filter(
          (item) =>
            item.classes_id == parseInt(this.selected_row.classes_id) &&
            (item.student_group_id == parseInt(this.selected_row.student_group_id) ||
             item.student_group_id == parseInt(constants.COMMON_GROUP_ID))
        );
        
        this.option_subjects = this.subjects.filter(
          (item) =>
            item.classes_id == parseInt(this.selected_row.classes_id) &&
           (item.student_group_id == parseInt(this.selected_row.student_group_id) ||
             item.student_group_id == parseInt(constants.COMMON_GROUP_ID)) &&
            item.is_optional == 1
        );

        
        this.current_subjects = [];
        this.current_subjects = this.selected_row.optional_subjects.toString().split(/[ ,]+/);
        this.selectedSubjects = this.selected_row.compulsory_subjects.toString().split(/[ ,]+/);
      
        this.findSections();
        this.filterSections();
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
